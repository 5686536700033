import React from 'react'

const Services = () => {
  return (
    
    <section className="vs-serivce-wrapper space-top" id="Service">
    <div className="container">
        <div className="row text-center justify-content-center">
            <div className="col-md-11 col-lg-9 col-xl-7">
                <div className="section-title">
                    <h2 className="sec-title">Our Services</h2>
                    <div className="sec-divider"></div>
                    <p>We Provide Best And Fast Service In Mobile Repairing. We Do All Service For Your Mobile.</p>
                </div>
            </div>
        </div>
        <div className="row vs-carousel" data-slide-show="3">
            <div className="col-md-6 col-sm-6 col-lg-4">
                <div className="service-card mb-30">
                    <div className="service-img"><img src="S1.jpg" alt="img" className="w-100" /></div>
                    <div className="service-content">
                        <h3 className="h4 service-title text-white">Software</h3>
                        <p className="service-text text-white">Quickly Repair Your Mobile in Software crash or hanging problem</p>
                    </div>
                    <div className="title-box"><h4 className="h4 mb-0">Software</h4></div>
                </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-4 ">
                <div className="service-card mb-30">
                    <div className="service-img"><img src="S2.jpg" alt="img" className="w-100" /></div>
                    <div className="service-content">
                        <h3 className="h4 service-title text-white">Crack Screen</h3>
                        <p className="service-text text-white">Quickly repair Your Mobiles Broken Screen With Genuine Parts</p>
                    </div>
                    <div className="title-box"><h4 className="h4 mb-0">Crack Screen</h4></div>
                </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-4">
                <div className="service-card mb-30">
                    <div className="service-img"><img src="S3.jpg" alt="img" className="w-100" /></div>
                    <div className="service-content">
                        <h3 className="h4 service-title text-white">Ic Work</h3>
                        <p className="service-text text-white">We Do All Type Of IC Work Like Reprograming, Changing which Is Damaged Ic On Mobile Phone</p>
                    </div>
                    <div className="title-box"><h4 className="h4 mb-0">Ic Work</h4></div>
                </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-4 d-md-block d-none d-lg-none">
                <div className="service-card mb-30">
                    <div className="service-img"><img src="S4.jpg" alt="img" className="w-100" /></div>
                    <div className="service-content">
                        <h3 className="h4 service-title text-white">Water damaged</h3>
                        <p className="service-text text-white">We Repair Your Water damaged Mobile As well As New</p>
                    </div>
                    <div className="title-box"><h4 className="h4 mb-0">Water Damaged</h4></div>
                </div>
            </div>
           
        </div>
    </div>
</section>
  )
}

export default Services