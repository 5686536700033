import React, { useState } from 'react'
import $ from 'jquery';
import Alert from '@mui/material/Alert';





const TrackPhone = () => {
    const [MID, setMID] = useState(0);
    const [CNUM, setCNUM] = useState(0);
    console.log(CNUM);

    function subtrack(e) {
        e.preventDefault();
        if (MID.length > 4) {
            $("#alert").delay("fast").fadeIn();
            $("#alert").delay(5000).fadeOut();
        }else{
            $("#err").delay("fast").fadeIn();
            $("#err").delay(3000).fadeOut();
            $( "#MobileId" ).focus();
        }
    }
        return (

            <section className="appointment-wrapper space bgT1" id="Track">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 align-self-center mb-25 mb-lg-0">
                            <p className="h4 font-theme2 fw-normal text-theme">10% off On Your 1st Visit.</p>
                            <h2 className="sec-title mb-1">Track Your Phone</h2>
                            <p className="pe-xl-5 me-xl-2 text-title">
                                Track Your Phone's Status Without Any Call Or Message. We Provide Updated data On Site
                            </p>

                        </div>
                        <div className="col-lg-6">
                            <div className='ds-n ' id="alert">
                                <Alert variant="filled" severity="success">
                                    Mobile Details Send In Registred Mobile Number
                                </Alert>
                            </div>
                            <div className='ds-n ' id="err">
                                <Alert variant="filled" severity="warning">
                                    Minimum 5 Digit Serial Number Required
                                </Alert>
                            </div>
                            <form className="appointment-form form-wrap1 img-fluid bgT4" action="#">
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <label htmlFor="brandName">Enter Your Mobile 5 Digit Serial Number *</label> <input onChange={e => setMID(e.target.value)} type="number" id="MobileId" name="SN" className="form-control shadow fw-bold" placeholder="For Example 10110 *" />
                                    </div>
                                    <div className="col-12 form-group"><label htmlFor="apNumber">Your phone number </label> <input onChange={e => setCNUM(e.target.value)} type="number" className="form-control shadow fw-bold" id="CsNumber" placeholder="Phone Number (Optional)" /></div>
                                    <div className="col-12">
                                        <button type="submit" onClick={subtrack} id="TrackSubmit" className="vs-btn">Submit Now<i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

export default TrackPhone