import React from "react";
import Carousel from "react-bootstrap/Carousel";

const Banner = () => {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img className="d-block w-100" src="banner1.png" alt="First slide" />

        <Carousel.Caption>
        <div className="RipleBig" ></div>
          <h1 className="clr-R" >Mobiles 24</h1>
          <p
            className="ls-l ls-responsive ls-hide-phone clr-R"
            style={{fontWeight: 700, letterSpacing:'0.06em'}}
        >
           Mobile Repair in 30 Min.* With Warranty
        </p>
        </Carousel.Caption>
      </Carousel.Item>


    </Carousel>
  );
};

export default Banner;
