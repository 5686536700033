import React from 'react'
import './App.css';
import NumberSpakes from './Compo/NumberSpakes';
import TopHead from './Compo/TopHead';
import KnowUs from './Compo/KnowUs';
import Services from './Compo/Services';
import Last from './Compo/Last';
import TrackPhone from './Compo/TrackPhone';
import Team from './Compo/Team';
import Whyus from './Compo/Whyus';
import Banner from './Compo/Banner';
function App() {

  
  return (
    <div>
     <TopHead/>
     <Banner/>
     <TrackPhone/>
     <NumberSpakes/>
     <Whyus/>
     <Services/>
     <Team/>
     <KnowUs/>
     <Last/>
    </div>
  );
}

export default App;
