import React from 'react'

const NumberSpakes = () => {
  return (
    <div className='bg3' >
    <div className="vs-counter-wrapper space-bottom">
        <div className="container tac">
            <div className="counter-area bg-fluid">
                <div className="row justify-content-around justify-content-lg-between">

                <div className="section-title">
                    <h2 className="sec-title">Numbers Speaks</h2>
                    <div className="sec-divider"></div>
                </div>

                    <div className="col-5 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-xl-start">
                        <div className="d-md-flex mb-30">
                            <span className="iconNS"><img style={{maxHeight: "60px"}} src='happycs.png' alt='img' /></span>
                            <div className="media-body mt-2 mt-sm-0 ms-sm-3">
                                <h2 className="fw-extrabold mb-0 lh-1"><span className="counter">5350</span>+</h2>
                                <p className="mb-0">Happy Costumers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-xl-start">
                        <div className="d-md-flex mb-30">
                            <span className="  iconNS"><img style={{maxHeight: "60px"}} src='Phr.png' alt='img' /></span>
                            <div className="media-body mt-2 mt-sm-0 ms-sm-3">
                                <h2 className="fw-extrabold mb-0 lh-1"><span className="counter">10203</span>+</h2>
                                <p className="mb-0">Phones Repaired</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-xl-start">
                        <div className="d-md-flex mb-30">
                            <span className="  iconNS"><img style={{maxHeight: "60px"}} src='Del.png' alt='img' /></span>
                            <div className="media-body mt-2 mt-sm-0 ms-sm-3">
                                <h2 className="fw-extrabold mb-0 lh-1"><span className="counter">9813</span>+</h2>
                                <p className="mb-0">Phones Delivered</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-xl-start">
                        <div className="d-md-flex mb-30">
                            <span className="  iconNS"><img style={{maxHeight: "60px"}} src='rating.png' alt='img' /></span>
                            <div className="media-body mt-2 mt-sm-0 ms-sm-3">
                                <h2 className="fw-extrabold mb-0 lh-1"><span className="counter">4.7</span>+</h2>
                                <p className="mb-0">Rating</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></div>
  )
}

export default NumberSpakes