import React from 'react'

const knowus = () => {
  return (
    <section className="vs-about-wrapper space-top space-md-bottom" id="Award">
        <div className="shape1 bg-theme d-none d-xxl-block"></div>
        <div className="container">
            <div className="row gx-60">
                <div className="col-lg-5 mb-30">
                    <div className="about-img BdrR5"><img src="F7.jpg" alt="img" className="w-100 BdrR5" /></div>
                </div>
                <div className="col-lg-7 align-self-center mb-30">
                    <div className="about-content pb-xl-2">
                        <h2 className="h4 text-theme mb-2 font-theme2">Did You Know</h2>
                        <div className="row">
                            <div className="col-lg-10 col-xl-9">
                                <h3 className="title h1">We are award Winner in<span className="text-theme"> <br/> Fast services</span></h3>
                            </div>
                        </div>
                        <p className="fs-md mb-lg-3 pb-xl-3">
                            Professionally Repair Your Mobile Problem With high-quality Parts And genuine parts</p>
                        <a href="tel:+917888182888" className="vs-btn mt-1"><span className="shape"></span> Call Now<i className="fas fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default knowus