import React from 'react'

const Whyus = () => {
  return (
    <section className="vs-about-wrapper" id="WhyUs">
    <div className="shape1 bg-theme d-none d-xxl-block"></div>
    <div className="container">
        <div className="row gx-60">
            <div className="col-lg-7 align-self-center mb-30">
                <div className="about-content pb-xl-2">
                    <h2 className="h4 text-theme mb-2 font-theme2">Why Us</h2>
                    <div className="row">
                        <div className="col-lg-10 col-xl-9">
                            <h3 className="title h1">We are <span className="text-theme">Certified</span> And Provide <span className="text-theme"> Warranty </span>On Repair</h3>
                        </div>
                    </div>
                    <p className="fs-md mb-lg-3 pb-xl-3">
                        Professionally Repair Your Mobile In Just <span className="text-theme"> 30 Minutes* </span> using high-quality Parts With <span className="text-theme"> Warranty </span></p>
                    <a href="tel:+917888182888" className="vs-btn mt-1"><span className="shape"></span> Contact Us<i className="fas fa-chevron-right"></i></a>
                </div>
            </div>
            <div className="col-lg-5 mb-30">
                <div className="about-img "><img src="F6.jpg" alt="img" className="w-100 BdrR5" /></div>
            </div>
            
        </div>
    </div>
</section>
  )
}

export default Whyus