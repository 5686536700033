import React, { useState } from 'react'
import $ from 'jquery';


const TopHead = () => {
    const [Menu, setMenu] = useState(false);
    const toggleClass = () => {
        setMenu(!Menu);
    };


    $(function () {
        $(".preloader").fadeOut();
    });
    $(document).on("click", ".preloaderCls", function () {
        $(".preloader").css("display", "none");
    });

    return (
        <>
            <div className="preloader">
                <button className="vs-btn preloaderCls" >Cancel Preloader</button>
                <div className="preloader-inner">
                    <div className="pt-1">
                        <div className="spin text-white"><i className='fa fa-cog' /></div>
                    </div>
                </div>
            </div>

            <div className={Menu ? 'vs-menu-wrapper vs-body-visible' : 'vs-menu-wrapper '}>
                <div className="vs-menu-area text-center">
                    <button onClick={toggleClass} className="vs-menu-toggle pd2"><i className="fa fa-times"></i></button>
                    <div className="mobile-logo">
                        <a href="#Head"><img src="WebHead.png" alt="Mobiles" /></a>
                    </div>

                    <div className="vs-mobile-menu">
                        <ul>
                            <li onClick={toggleClass} ><a href="#Head">Home</a></li>
                            <li onClick={toggleClass} ><a href="#Track">Track Phone</a></li>
                            <li onClick={toggleClass} ><a href="#Service">Service</a></li>
                            <li onClick={toggleClass} ><a href="#Award">Award</a></li>
                            <li onClick={toggleClass} ><a href="#Team">Team</a></li>
                            <li onClick={toggleClass} ><a rel="noopener noreferrer" href="https://old.mymobiles24.com">Member Login</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <header className="header-wrapper header-layout1" id="Head">
                <div className="header-top bg-theme d-none d-lg-block">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <ul className="header-info text-white list-unstyled m-0 fs-xs clr-w">
                                    <li><i className="far fa-envelope"></i>support@mobiles24.in</li>
                                    <a target="_blank" rel="noopener noreferrer" href='https://g.page/mobiles24' className='clr-w' ><li><i className="fa fa-map-marked"></i>178 Akashganga Supela Bhilai 490021</li></a>
                                    <li><i className="far fa-clock"></i>Wed - Mon: 11:00 am - 10:00 pm</li>
                                    <a target="_blank" rel="noopener noreferrer" href="tel:+917888182888" className='clr-w' > <li><i className="fa fa-phone"></i>7888 182 888</li></a>
                                </ul>
                            </div>
                            <div className="col-auto text-end">
                                <div className="header-social d-inline-block">
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/mobiles_24/"><i className="fab fa-instagram"></i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://wa.me/917888182888"><i className="fab fa-whatsapp"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sticky-active py-3 py-lg-0" >
                    <div className="container position-relative">
                        <div className="row justify-content-between">
                            <div className="col-9 col-lg-auto align-self-center">
                                <div className="header-logo">
                                    <a href="#Head"><img src="WebHead.png" width={350} alt="M24" /></a>
                                </div>
                            </div>
                            <div className="col-3 col-lg-auto col-xxl-6 d-flex justify-content-end">
                                <nav className="main-menu menu-style1 d-none d-lg-block">
                                    <ul>
                                        <li><a href="#Head">Home</a></li>
                                        <li><a href="#Track">Track Phone</a></li>
                                        <li><a href="#Service">Service</a></li>
                                        <li><a href="#Award">Award</a></li>
                                        <li><a href="#Team">Team</a></li>
                                        <li><a rel="noopener noreferrer" href="https://old.mymobiles24.com">Member Login</a></li>

                                    </ul>
                                </nav>
                                <button onClick={toggleClass} className="vs-menu-toggle d-inline-block d-lg-none"><i className="fa fa-bars"></i></button>
                            </div>
                            <div className="col-xl-auto d-none d-xl-block align-self-center">
                                <div className="header-btn">
                                    <a href="tel:7888182888" className="vs-btn ms-3 d-none d-xl-inline-block">Get a Services<i className="fa fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="header-top bg-theme d-lg-none">

                    <div className="container ">
                        <div className="row justify-content-between ">
                            <div className="col-auto col-lg-9">
                                <ul className="header-info text-white list-unstyled m-0 fs-xs clr-w">
                                    <a target="_blank" rel="noopener noreferrer" href='https://g.page/mobiles24' className='clr-w' ><li><i className="fa fa-map-marked"></i>178 Akashganga Supela Bhilai 490021</li></a>
                                    <a target="_blank" rel="noopener noreferrer" href="tel:7888182888" className='clr-w' > <li><i className="fa fa-phone"></i>7888 182 888</li></a>
                                </ul>
                            </div>
                            <div className="col-auto text-start col-lg-3">
                                <div className="header-social d-inline-block">
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/mobiles_24/"><i className="fab fa-instagram"></i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://wa.me/917888182888"><i className="fab fa-whatsapp"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>
        </>
    )
}

export default TopHead