import React from 'react'

const Team = () => {
    return (
        <section className="vs-team-wrapper bg3 space-md-top" id="Team">
            <div className="container">
                <div className="row text-center justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12 text-center justify-content-center">
                        <div className="section-title">
                            <h2 className="sec-title">Our Team</h2>
                            <div className="sec-divider"></div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-5 col-sm-6 mb-25">
                        <div className="vs-team-pill">
                            <div className="team-head">
                                <div className="team-img">
                                    <img src="Team1.jpg" alt="Teamimage" />
                                </div>
                            </div>
                            <div className="team-body">
                                <h3 className="name h4 mb-0">Gaurav Nathani</h3>
                                <span className="degi text-theme">Founder</span>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-2 col-md-5 col-sm-6 mb-25">
                        <div className="vs-team-pill">
                            <div className="team-head">
                                <div className="team-img" data-overlay="theme">
                                    <img src="Team2.jpg" alt="Teamimage" />
                                </div>
                            </div>
                            <div className="team-body">
                                <h3 className="name h4 mb-0"> Gautam Nathani </h3>
                                <span className="degi text-theme">CEO</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-4 col-sm-6 mb-25">
                        <div className="vs-team-pill">
                            <div className="team-head">
                                <div className="team-img" data-overlay="theme">
                                    <img src="Team4.jpeg" alt="Teamimage" />
                                </div>
                            </div>
                            <div className="team-body">
                                <h3 className="name h4 mb-0"> Karan Pamnani </h3>
                                <span className="degi text-theme">Manager</span>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-2 col-md-4 col-sm-6 mb-25">
                        <div className="vs-team-pill">
                            <div className="team-head">
                                <div className="team-img" data-overlay="theme">
                                    <img src="Team3.jpg" alt="Teamimage" />
                                </div>
                            </div>
                            <div className="team-body">
                                <h3 className="name h4 mb-0"> Krishna </h3>
                                <span className="degi text-theme">Member</span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default Team