import React from 'react'

const Last = () => {
  return (
    <footer className="footer-wrapper footer-layout1 bg10">
            <div className="widget-area pt-100 pb-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-xl-4">
                            <div className="widget footer-widget">
                                <h3 className="widget_title">About Us</h3>
                                <div className="vs-widget-about">
                                    <p className="widget-about-text mb-25 pe-xl-5">
                                        We Provide Best Mobile Services In Bhilai And Near By Area. We Also Have An Award Of Fast Service In Mobile Repair. We Do All Services Be It Software Related Or Hardware.
                                    </p>
                                    <a href="https://g.page/mobiles24" className="vs-btn style6"><i className="fa fa-map-marker-alt"></i> View Map</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-4">
                            
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-4">
                            <div className="widget footer-widget">
                                <h4 className="widget_title">Connect With Us</h4>
                                <form className="newsletter-form">
                                    <span className="form-icon"><i className="fa fa-envelope"></i></span> <input className="form-control" type="email" placeholder="Your Email Address" required="" />
                                    <button type="submit" className="vs-btn mask-style1">Subscribe</button>
                                </form>
                                <div className="mt-4 pt-2">
                                    <h5 className="text-white mb-0 me-2 d-inline-block">Follow On:</h5>
                                    <div className="footer-social d-inline-block">
                                        <a href="https://www.instagram.com/mobiles_24/"><i className="fab fa-instagram"></i></a><a href="https://wa.me/917888182888"><i className="fab fa-whatsapp"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-style1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12"> 
                        <a href='#Head' > <button type="button" className="footer-scroll scrollToTop"><i className="fa fa-angle-up"></i></button></a>
                        </div>
                        <div className="col-md-8 col-lg-6 text-center text-md-start">
                            <p className="py-2 py-md-0 mb-0">
                                Copyright <i className="fa fa-copyright"></i> {new Date().getFullYear()} <a className="text-white me-1" href="#Head">Mobiles 24.</a>All rights reserved.
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </footer>
  )
}

export default Last